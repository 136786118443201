import createProps from '@kissui/helpers/src/props.helpers'
import viewportHelper from '@kissui/helpers/src/viewport.helpers'
import { getMarketCode, getLangCode, interpolateMarketLang } from '@kissui/helpers/src/dataLayer'
import { handlePromoClick } from '@kissui/helpers/src/gtmEvents'

class FooterLinks extends HTMLElement {
    constructor() {
        super()
        this.market = getMarketCode()
        this.language = getLangCode()
        this.buttonElements = null
    }

    connectedCallback() {
        this.props = createProps(this.attributes)
        this.render()
    }

    render() {
        this.unbindEvent()

        const { id, name, creative, position } = this.props.campaign
        const { heading } = this.props.copywriting

        let headingRender = ''
        if (heading) {
            headingRender = `<h2 class="sr-only">${heading}</h2>`
        }

        this.innerHTML = `<nb-container
                campaign_id="${id}"
                campaign_name="${name}"
                campaign_creative="${creative}"
                campaign_position="${position}"
                classname="pt4 pb4"
                contrast="light">
                ${headingRender}
                ${this.renderColumns()}
            </nb-container>`

        this.buttonElements = this.querySelectorAll('button')
        this.liveChatElement = this.querySelector('a[href="#live-chat"]')
        this.allLinkElements = this.querySelectorAll('ul li a')
        this.bindEvent()
    }

    renderColumns() {
        const { columns = [], contact } = this.props.copywriting
        columns.push(contact)

        return columns.reduce(
            (output, item, index) => `${output}${this.renderItems(item, index)}`,
            ''
        )
    }

    renderItems({ heading, links }, id) {
        const renderedItems = links.reduce(
            (output, item) => `${output}${this.renderItem(item)}`,
            ''
        )

        if (viewportHelper.is.mobile) {
            return `<div>
                  <button class="t-md-400-sl"
                      role="heading"
                      aria-level="3"
                      aria-expanded="false"
                      id="footer-link-heading-${id}"
                      aria-controls="footer-link-region-${id}">${heading}</button>
                  <ul class="t-sm-400-sl"
                      aria-hidden="true"
                      role="region"
                      aria-labelledby="footer-link-heading-${id}"
                      id="footer-link-region-${id}">${renderedItems}</ul>
                </div>`
        } else {
            return `<div>
                  <h3 class="t-md-400-sl">${heading}</h3>
                  <ul class="t-sm-400-sl">${renderedItems}</ul>
                </div>`
        }
    }

    renderItem({
        label,
        link,
        icon,
        phone,
        sublabel_first_line,
        sublabel_second_line,
        target_blank
    }) {
        if (link === '' || label === '') {
            return
        }
        const url = interpolateMarketLang(link, this.market, this.language)

        let target = '_self'
        if (target_blank === 'true') {
            target = '_blank'
        }

        // Links for contact column
        if (icon || phone || sublabel_first_line || sublabel_second_line) {
            return `<li>
                    <a href="${url}" target="${target}">
                        ${icon && `<nb-icon icon="${icon}"></nb-icon>`}
                        <span>${label}</span>
                        ${phone && `<span>${phone}</span>`}
                    </a>
                    <span>${sublabel_first_line}</span>
                    <span>${sublabel_second_line}</span>
                </li>`
        }

        // Other links
        return `<li>
                <a href="${url}" target="${target}">${label}</a>
            </li>`
    }

    toogle(e) {
        let isOpen = e.currentTarget.getAttribute('aria-expanded')
        if (isOpen === 'false') {
            e.currentTarget.setAttribute('aria-expanded', 'true')
            e.currentTarget.nextElementSibling.setAttribute('aria-hidden', 'false')
        } else {
            e.currentTarget.setAttribute('aria-expanded', 'false')
            e.currentTarget.nextElementSibling.setAttribute('aria-hidden', 'true')
        }
    }

    openChat() {
        if (!!window.dydu && !!window.dydu.chatbox) {
            window.dydu.chatbox.togglePopin(true)
        } else if (window.calleoChat) {
            window.calleoChat('startChat')
        }
    }
    linkClick({ textContent, index }) {
        const { campaign } = this.props

        handlePromoClick({
            campaign,
            cta_name: `LINK - ${index + 1} - ${textContent}`
        })
    }

    bindEvent() {
        if (this.allLinkElements?.length) {
            this.allLinkElements.forEach((element, index) => {
                const textContent = element.textContent
                element.boundLinkClick = this.linkClick.bind(this, { textContent, index })
                element.addEventListener('click', element.boundLinkClick)
            })
        }

        if (this.buttonElements?.length) {
            this.buttonElements.forEach(element => {
                element.boundHandleClick = this.toogle.bind(element)
                element.addEventListener('click', element.boundHandleClick)
            })
        }
        if (this.liveChatElement) {
            this.liveChatElement.boundLiveChatClick = this.openChat.bind(this.liveChatElement)
            this.liveChatElement.addEventListener('click', this.liveChatElement.boundLiveChatClick)
        }
    }

    unbindEvent() {
        if (this.allLinkElements?.length) {
            this.allLinkElements.forEach(element => {
                element.removeEventListener('click', element.boundLinkClick)
            })
        }

        if (this.buttonElements?.length) {
            this.buttonElements.forEach(element => {
                element.removeEventListener('click', element.boundHandleClick)
            })
        }
        if (this.liveChatElement) {
            this.liveChatElement.removeEventListener(
                'click',
                this.liveChatElement.boundLiveChatClick
            )
        }
    }

    disconnectedCallback() {
        this.unbindEvent()
    }
}

customElements.get('nb-footer-links') || customElements.define('nb-footer-links', FooterLinks)
export default FooterLinks
